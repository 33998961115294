.imageBox{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginMainBox{
    width: 40%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.loginChildBox{
    padding: 80px 10%;
    display: flex;
    width: 60%;
}





 /* for mobile view */

 @media screen and (max-width: 600px) {
    .imageBox{
       display: none;
    }
    .loginMainBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .loginChildBox{
        padding: 50px 6%;
        display: flex;
        width: 100%;
    }
  }