.arrow-container {
    width: 100%;
    height: 50px;
    position: relative;
    /* overflow: hidden; */
  }
  
  .arrow {
    position: relative;
    height: 5px;
    background-color: #BEBEBE;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    animation: extend 2s forwards;


    
  }
  
  .arrow::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-width: 10px 0 10px 20px;
    border-style: solid;
    border-color: transparent transparent transparent #BEBEBE;
  }
  
  @keyframes extend {
    0% {
      left: 0;
      width: 0;
    }
    100% {
      left: 0;
      width: 100%;
    }
  }

  .mainBox{
    padding: 24px;
    display: flex;
  }

  .marginBox{
    margin: 0px 32px;
  }

  .sliderMainBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .sliderTextfieldBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .calculateButtonBox{
    margin-left: 10px;
    width: 20%;
  }

  .transferSelectBox{
    height: 250px;
    width: 30%;
    border: 1px solid #E0E6ED;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    border-radius: 10px;
  }

  #sidePointsTypo{
    font-weight: bold;
    font-size: 22px;
    color: gray;
  }

  .transferMainBox{
    margin-top: 20px;
    /* min-height: 600px; */
    height: 330px;
    border-radius: 10px;
    background-color: white;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    flex-direction: column;
    padding: 20px;
    justify-content: space-evenly;
    align-items: center;
  }

  .pointExchangeBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 25px 25px 25px;
    border-radius: 30px;
  }

  #memberIdTypo{
    font-weight: bold;
    font-size: 16px;
  }

  .modalMainBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600;
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    width: 50%;
  }

  #modalMembershipIdTypo{
    font-weight: bold;
   
  }


  /* for mobile view */

  @media screen and (max-width: 600px) {
    .mainBox{
      padding: 0px;
     
    }
    .marginBox{
      margin: 0px 0px;
    }
    .sliderMainBox{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex-direction: column;
    }
    .sliderTextfieldBox{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .calculateButtonBox{
      margin-left: 0px;
      width: 100%;
      margin-top: 20px;
    }

    .transferSelectBox{
      height: 200px;
      width: 150px;
      border: 1px solid #E0E6ED;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding: 10px;
      border-radius: 10px;
    }
    #sidePointsTypo{
      font-weight: bold;
      font-size: 14px;
      color: gray;
    }

    .transferMainBox{
      margin-top: 20px;
      height: 485px;
      border-radius: 10px;
      background-color: white;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 5%;
      flex-direction: column;
      padding: 20px;
      justify-content: space-evenly;
      align-items: center;
    }
    .pointExchangeBox{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 25px 25px 0px 25px;
      border-radius: 30px;
    }

    #memberIdTypo{
      /* font-weight: bold; */
      font-size: 14px;
    }
    .modalMainBox{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 600;
      background-color: white;
      padding: 10px;
      border-radius: 10px;
      width: 80%;
    }
    #modalMembershipIdTypo{
      font-weight: bold;
      font-size: 13px;
    }
  }
  