/* @font-face {
  font-family: 'CircularStd';
  font-weight: 400;
  font-style: normal;
  src: local('CircularStd'), url('CircularStd-Book.otf') format('opentype');
}

@font-face {
  font-family: 'CircularStd';
  font-weight: 500;
  font-style: normal;
  src: local('CircularStd'), url('CircularStd-Medium.otf') format('opentype');
} */

@font-face {
  font-family: 'Gilroy';
  /* src: url('./fonts/Gilroy-Regular.ttf'); */
  src: url('./Gilroy-Regular.ttf');
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-Light.ttf');
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-Bold.ttf');
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-SemiBold.ttf');
}

@font-face {
  font-family: 'Gilroy';
  src: url('./Gilroy-Medium.ttf');
}

/* @font-face {
  font-family: 'CircularStd';
  font-weight: 700;
  font-style: normal;
  src: local('CircularStd'), url('CircularStd-Bold.otf') format('opentype');
} */

/* img.w-class {
  height: 330px;
  margin-bottom: 8px;
  margin-right: 10px;
}

.container-login {
  max-width: 100%;
  width: 1400px;
  margin: 0 auto;
  display: block;
} */
